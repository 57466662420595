import React from 'react'

const ContextModule = React.createContext()

class ContextProvider extends React.Component {
  // Context state
  state = {
    value: {
      session: {
        user: "",
        token: "",
        refreshToken: "",
      },
      headerState: false
    }
  }

  // Method to update state
  setValue = (value) => {
    let temp = {
      ...this.state.value,
      ...value,
    }
    this.setState({
      value: temp
    })
  }

  render() {
    const { children } = this.props
    const { value } = this.state
    const { setValue } = this

    return (
      <ContextModule.Provider
        value={{
          value,
          setValue,
        }}
      >
        {children}
      </ContextModule.Provider>
    )
  }
}

export { ContextProvider }
export const ContextConsumer = ContextModule.Consumer

export default ContextModule
import React, { Component } from "react";
import { isMobile } from "react-device-detect";

const text = `
DMCA policy

This Digital Millennium Copyright Act policy (“Policy”) applies to the “ReLeaf” mobile application (“Mobile Application” or “Service”) and any of its related products and services (collectively, “Services”) and outlines how Implementacion de Tecnologia para el Desarrollo Empresarial (doing business as “Blankit”, “we”, “us” or “our”). addresses copyright infringement notifications and how you (“you” or “your”) may submit a copyright infringement complaint.

Protection of intellectual property is of utmost importance to us and we ask our users and their authorized agents to do the same. It is our policy to expeditiously respond to clear notifications of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act (“DMCA”) of 1998, the text of which can be found at the U.S. Copyright Office website. This DMCA policy has been created with the help of https://www.websitepolicies.com/dmca-policy-generator

What to consider before submitting a copyright complaint

Please note that if you are unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.

We may, at our discretion or as required by law, share a copy of your notification or counter-notification with third parties. If you are concerned about your information being forwarded, you may wish to hire an agent to report infringing material for you.

Notifications of infringement

If you are a copyright owner or an agent thereof, and you believe that any material available on our Services infringes your copyrights, then you may submit a written copyright infringement notification (“Notification”) using the contact details below pursuant to the DMCA. All such Notifications must comply with the DMCA requirements. You may refer to a DMCA takedown notice generator or other similar services to avoid making mistake and ensure compliance of your Notification.

Filing a DMCA complaint is the start of a pre-defined legal process. Your complaint will be reviewed for accuracy, validity, and completeness. If your complaint has satisfied these requirements, our response may include the removal or restriction of access to allegedly infringing material as well as a permanent termination of repeat infringers’ accounts. A backup of the terminated account’s data may be requested, however, we may not be able to provide you with one and, as such, you are strongly encouraged to take your own backups.

If we remove or restrict access to materials or terminate an account in response to a Notification of alleged infringement, we will make a good faith effort to contact the affected user with information concerning the removal or restriction of access, along with instructions for filing a counter-notification.

Notwithstanding anything to the contrary contained in any portion of this Policy, Blankit reserves the right to take no action upon receipt of a DMCA copyright infringement notification if it fails to comply with all the requirements of the DMCA for such notifications.

Counter-notifications

A user who receives a copyright infringement Notification may make a counter-Notification pursuant to sections 512(g)(2) and (3) of the US Copyright Act. If you receive a copyright infringement Notification, it means that the material described in the Notification has been removed from our Services or access to the material has been restricted. Please take the time to read through the Notification, which includes information on the Notification we received. To file a counter-notification with us, you must provide a written communication compliant with the DMCA requirements.

Please note that if you are not sure whether certain material infringes the copyrights of others or that the material or activity was removed or restricted by mistake or misidentification, you may wish to contact an attorney before filing a counter-notification.

Notwithstanding anything to the contrary contained in any portion of this Policy, Blankit reserves the right to take no action upon receipt of a counter-notification. If we receive a counter-notification that complies with the terms of 17 U.S.C. § 512(g), we may forward it to the person who filed the original Notification.

The process described in this Policy does not limit our ability to pursue any other remedies we may have to address suspected infringement.

Changes and amendments

We reserve the right to modify this Policy or its terms related to the Mobile Application and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page, post a notification in the Mobile Application. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.

An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Mobile Application and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.

Reporting copyright infringement

If you would like to notify us of the infringing material or activity, we encourage you to contact us using the details below:

altaga@protonmail.com

This document was last updated on October 5, 2023
`;

class ReLeafCopy extends Component {
  render() {
    if (isMobile) {
      return (
        <div style={{ width: "90%", margin: "5%"}}>
          {text.split("\n\n").map((paragraph) => (
            <p>
              {paragraph
                .split("\n")
                .reduce((total, line) => [total, <br />, line])}
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <div style={{ width: "90%", margin: "5%" }}>
          {text.split("\n\n").map((paragraph) => (
            <p>
              {paragraph
                .split("\n")
                .reduce((total, line) => [total, <br />, line])}
            </p>
          ))}
        </div>
      );
    }
  }
}

export default ReLeafCopy;
// Basic
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/App.css";

// Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Pages
import Main from "./pages/main";
import About from "./pages/about";
import Fastyd from "./pages/fastyd";
import EffiSend from "./pages/effisend";
import OutLay from "./pages/outlay";
import Azure from "./pages/azure-rehab";

// Cookies
import { CookiesProvider } from "react-cookie";

// Header and footer

import Base from "./components/base";

// React Context

import { ContextProvider } from "./utils/contextModule";
import EffiSendPrivacy from "./sideDocs/effisendPrivacy";
import EffiSendCopy from "./sideDocs/effisendCopy";
import LightsaverCopy from "./sideDocs/lightsaverCopy";
import LightsaverPivacy from "./sideDocs/lightsaverPrivacy";
import ReLeafPrivacy from "./sideDocs/releafPrivacy";
import ReLeafCopy from "./sideDocs/releafCopy";
import AptosVaultPrivacy from "./sideDocs/aptosvaultPrivacy";
import AptosVaultCopy from "./sideDocs/aptosvaultCopy";
import EVaultCopy from "./sideDocs/evaultCopy";
import EVaultPrivacy from "./sideDocs/evaultPrivacy";
import SaveraPrivacy from "./sideDocs/saveraPrivacy";
import SaveraCopy from "./sideDocs/saveraCopy";
import Sav3anaPrivacy from "./sideDocs/sav3anaPrivacy";
import Sav3anaCopy from "./sideDocs/sav3anaCopy";
import SafeVaultPrivacy from "./sideDocs/safePrivacy";
import SafeVaultCopy from "./sideDocs/safeCopy";
import BNBVaultCopy from "./sideDocs/bnbCopy";
import BNBVaultPrivacy from "./sideDocs/bnbPrivacy";
import WhiteHoleCopy from "./sideDocs/whCopy";
import WhiteHolePrivacy from "./sideDocs/whPrivacy";

class App extends Component {
  render() {
    return (
      <Router>
        <CookiesProvider>
          <ContextProvider>
            <Base>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/fastyd" element={<Fastyd />} />
                <Route path="/effisend" element={<EffiSend />} />
                <Route path="/effisend/copyright" element={<EffiSendCopy />} />
                <Route path="/effisend/privacy" element={<EffiSendPrivacy />} />
                <Route path="/savera/copyright" element={<SaveraCopy />} />
                <Route path="/savera/privacy" element={<SaveraPrivacy />} />
                <Route path="/sav3ana/copyright" element={<Sav3anaCopy />} />
                <Route path="/sav3ana/privacy" element={<Sav3anaPrivacy />} />
                <Route path="/safe/copyright" element={<SafeVaultCopy />} />
                <Route path="/safe/privacy" element={<SafeVaultPrivacy />} />
                <Route path="/bnb/copyright" element={<BNBVaultCopy />} />
                <Route path="/bnb/privacy" element={<BNBVaultPrivacy />} />
                <Route path="/wh/copyright" element={<WhiteHoleCopy />} />
                <Route path="/wh/privacy" element={<WhiteHolePrivacy />} />
                <Route
                  path="/lightsaver/copyright"
                  element={<LightsaverCopy />}
                />
                <Route
                  path="/lightsaver/privacy"
                  element={<LightsaverPivacy />}
                />
                <Route path="/releaf/copyright" element={<ReLeafCopy />} />
                <Route path="/releaf/privacy" element={<ReLeafPrivacy />} />
                <Route
                  path="/aptosvault/copyright"
                  element={<AptosVaultCopy />}
                />
                <Route
                  path="/aptosvault/privacy"
                  element={<AptosVaultPrivacy />}
                />
                <Route path="/evault/copyright" element={<EVaultCopy />} />
                <Route path="/evault/privacy" element={<EVaultPrivacy />} />
                <Route path="/outlay" element={<OutLay />} />
                <Route path="/azure-rehab" element={<Azure />} />
                <Route path="*" element={<Main />} />
              </Routes>
            </Base>
          </ContextProvider>
        </CookiesProvider>
      </Router>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import industry from '../assets/ind.png';
import blockchain from '../assets/blockchain.png';
import ai from '../assets/ai.png';
import iot from '../assets/iot.png';
import part from '../assets/part.png';
import { MdWebAsset } from 'react-icons/md';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { isMobile } from 'react-device-detect';

const tweets = [
    "1531259464363352064",
    "1134395401748901889",
    "1474406963916197890",
]

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

class Main extends Component {
    render() {
        if (isMobile) {
            return (
                <div>
                    <div className='main-top'>
                        <div style={{
                            padding: '4vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="12">
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            paddingBottom: '4vh',
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Blankit
                                        </div>
                                        <div style={{
                                            paddingTop: '1vh',
                                            fontSize: '1.5rem',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            We develop digital solutions to solve the world's biggest problems.
                                        </div>
                                        <div style={{
                                            paddingTop: '2vh',
                                        }} />
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div style={{
                                        opacity: '0.5',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '5vh',
                                    }}>
                                        <img src={industry} alt="industry" width={'94%'} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '0vh 0vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Blankit Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                            }} md="2">
                                <Col xs="6">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={blockchain} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Blockchain
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={ai} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Artificial Intelligence
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={iot} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Internet of Things
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <MdWebAsset size={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Web Development
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <Row style={{
                            paddingTop: '10vh',
                        }}>
                            <Col xs="12" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Card style={{
                                    border: "12px solid #23356D",
                                    height: '40vh',
                                    width: '90vw',
                                    background: 'whitesmoke',
                                }}>
                                    <CardBody
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                        <div>
                                            <span style={{
                                                color: '#547cff',
                                                fontSize: '2.3rem',
                                            }}>
                                                Global {" "}
                                            </span>
                                            <span style={{
                                                color: 'black',
                                                fontSize: '2.3rem',
                                            }}>
                                                Partners
                                            </span>
                                        </div>
                                        <span style={{
                                            color: '#000',
                                            fontSize: '1.5rem',
                                            textAlign: "center"
                                        }}>
                                            Our partners are global companies that have a strong presence in the industry.
                                        </span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12"
                                style={{
                                    padding: "8vh 0vh",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <img src={part} alt="part" width={'90%'} />
                            </Col>
                        </Row>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '2vh',
                            textAlign: 'center',
                            background: `linear-gradient(to bottom, #23356D 0%,#23356D 60%,white 60%, white 100%)`,
                        }}>
                            <Card style={{
                                border: "12px solid white",
                                background: '#23356D',
                            }}>
                                <CardBody>
                                    <h1 style={{
                                        color: 'white',
                                    }}>
                                        Latest News
                                    </h1>
                                </CardBody>
                            </Card>
                            <Row style={{
                                paddingTop: '4vh',
                            }}>
                                <Col xs="12">
                                    <div style={{
                                        boxShadow: '0px 0px 20px #23356D',
                                        borderRadius: '10px',
                                    }}>
                                        <Col xs="12">
                                            <div style={{
                                                boxShadow: '0px 0px 20px #23356D',
                                                borderRadius: '10px',
                                            }}>
                                                <TwitterTweetEmbed
                                                    tweetId={tweets[getRndInteger(0, 2)]}
                                                />
                                            </div>

                                        </Col>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div >
            )
        }
        else {
            return (
                <div>
                    <div className='main-top'>
                        <div style={{
                            padding: '8vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="4">
                                    <div style={{
                                        paddingLeft: '10vw',
                                    }}>
                                        <div style={{
                                            paddingTop: '18vh',
                                            paddingBottom: '4vh',
                                            fontSize: '4vw',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Blankit
                                        </div>
                                        <div style={{
                                            paddingTop: '2vh',
                                            fontSize: '1.5vw',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            We develop digital solutions to solve the world's biggest problems.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                    </div>
                                </Col>
                                <Col xs="8">
                                    <div style={{
                                        opacity: '0.5',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '5vh',
                                    }}>
                                        <img src={industry} alt="industry" width={'80%'} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <hr />
                    <div className='main-two'>
                        <div style={{
                            padding: '12vh 0vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Blankit Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 10vw',
                            }}>
                                <Col xs="3">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={blockchain} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Blockchain
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={ai} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Artificial Intelligence
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={iot} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Internet of Things
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygon'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <MdWebAsset size={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Web Development
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '6vh 0vw',
                            textAlign: 'center',
                            background: 'whitesmoke',
                        }}>
                            <Row style={{
                                paddingTop: '10vh',
                                margin: '0vh 10vw',
                            }}>
                                <Col xs="5">
                                    <Card style={{
                                        border: "12px solid #23356D",
                                        height: '40vh',
                                        width: '60vh',
                                        background: 'whitesmoke',
                                    }}>
                                        <CardBody
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <div>
                                                <span style={{
                                                    color: '#547cff',
                                                    fontSize: '3vw',
                                                }}>
                                                    Global {" "}
                                                </span>
                                                <span style={{
                                                    color: 'black',
                                                    fontSize: '3vw',
                                                }}>
                                                    Partners
                                                </span>
                                            </div>

                                            <span style={{
                                                color: '#000',
                                                fontSize: '3vw',
                                            }}>

                                            </span>
                                            <span style={{
                                                color: '#000',
                                                fontSize: '1.5vw',
                                            }}>
                                                Our partners are global companies that have a strong presence in the industry.
                                            </span>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs="7">
                                    <img src={part} alt="part" height={'70%'} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 8vw',
                            textAlign: 'center',
                            background: `linear-gradient(to bottom, #23356D 0%,#23356D 60%,white 60%, white 100%)`,
                        }}>
                            <Card style={{
                                border: "12px solid white",
                                background: '#23356D',
                            }}>
                                <CardBody>
                                    <h1 style={{
                                        color: 'white',
                                    }}>
                                        Latest News
                                    </h1>
                                </CardBody>
                            </Card>
                            <Row style={{
                                paddingTop: '4vh',
                            }}>
                                {
                                    tweets.map((tweet, index) => {
                                        return (
                                            <Col key={index + tweet} xs="4">
                                                <div style={{
                                                    boxShadow: '0px 0px 20px #23356D',
                                                    borderRadius: '10px',
                                                }}>
                                                    <TwitterTweetEmbed
                                                        tweetId={tweet}
                                                    />
                                                </div>

                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default Main;
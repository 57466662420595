import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

let flag1 = false;
let flag2 = false;

const DropMenu = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <Dropdown isOpen={dropdownOpen} >
            <DropdownToggle
                tag="div"
                style={{
                    background: "rgba(255,255,255,0)",
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '1.3rem',
                    color: '#000',
                }}
                onMouseEnter={() => {
                    flag1 = true;
                    setDropdownOpen(true);
                }}
                onMouseLeave={() => {
                    flag1 = false;
                    setTimeout(() => {
                        if (!flag2) {
                            setDropdownOpen(false);
                        }
                    }, 500);
                }}
            >
                {
                    props.title
                }
            </DropdownToggle>
            <DropdownMenu style={{
                fontSize: '1.3rem',
                marginTop: '16px',
                background: 'rgba(255,255,255,1)',
            }}
                onMouseEnter={() => {
                    flag2 = true;
                }}
                onMouseLeave={() => {
                    flag2 = false;
                    setTimeout(() => {
                        if (!flag1) {
                            setDropdownOpen(false);
                        }
                    }, 500);
                }}>
                {
                    props.items.map((item, index) => {
                        return <DropdownItem href={props.urls[index]} key={index}>{item}</DropdownItem>
                    })
                }
            </DropdownMenu>
        </Dropdown>
    );
}

export default DropMenu;
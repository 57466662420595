import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import fastydLogo from '../assets/fastyd/fastydw.png';
import moralis from '../assets/fastyd/moralis.png';
import rapyd from '../assets/fastyd/rapyd.png';
import polygon from '../assets/fastyd/polygon.png';
import chainlink from '../assets/fastyd/chainlink.png';
import scheme from '../assets/fastyd/scheme.png';

class Fastyd extends Component {
    render() {
        if (isMobile) {
            return (
                <div>
                    <div className='fast-top'>
                        <div style={{
                            padding: '4vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="12">
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            opacity: '0.5',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '1vh',
                                        }}>
                                            <img src={fastydLogo} alt="industry" width={'24%'} />
                                        </div>
                                        <div style={{
                                            paddingBottom: '1vh',
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Fastyd
                                        </div>
                                        <div style={{
                                            paddingTop: '1vh',
                                            fontSize: '1.5rem',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Fastyd is a Superapp for fast Checkouts merging Web3 and traditional finance via Rapyd.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='fastButton' onClick={() => {
                                            window.open('https://www.youtube.com/watch?v=SDcjMM8oC0Y', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '0vh 0vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Fastyd Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                            }} md="2">
                                <Col xs="6">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={rapyd} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Rapyd API
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={moralis} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Moralis API
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={polygon} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Polygon Blockchain
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={chainlink} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Chainlink
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '8vh 0vh 16vw',
                            textAlign: 'center',
                        }}>
                            <Row>
                                <Col xs="12" style={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{
                                        width: '80%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            fontSize: "1.2rem",
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Fastyd is a Superapp that greatly improves Check-out processes on e-commerce employing Rapyd’s Checkout toolkit and APIs, while incorporating Web3 and blockchain capabilities and transactions.
                                        </p>
                                        <br />
                                        <Button className='fastButtonBm' onClick={() => {
                                            window.open('https://devpost.com/software/fastyd', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className='fast-top'>
                        <div style={{
                            padding: '8vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="4">
                                    <div style={{
                                        paddingLeft: '10vw',
                                    }}>
                                        <div style={{
                                            paddingTop: '18vh',
                                            paddingBottom: '4vh',
                                            fontSize: '4vw',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Fastyd
                                        </div>
                                        <div style={{
                                            paddingTop: '2vh',
                                            fontSize: '1.5vw',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Fastyd is a Superapp for fast Checkouts merging Web3 and traditional finance via Rapyd.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='fastButton' onClick={() => {
                                            window.open('https://www.youtube.com/watch?v=SDcjMM8oC0Y', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="8">
                                    <div style={{
                                        opacity: '1',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '5vh',
                                    }}>
                                        <img src={fastydLogo} alt="industry" style={{
                                            height: '70vh',
                                        }} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Fastyd Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 10vw',
                            }}>
                                <Col xs="3">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={rapyd} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Rapyd API
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={moralis} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Moralis API
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={polygon} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Polygon Blockchain
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygonf'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={chainlink} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Chainlink
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Fastyd Documentation
                            </h1>
                            <Row style={{
                                paddingTop: '10vh',
                            }}>
                                <Col xs="2">
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        border: '20px solid #ff614d',
                                        borderRadius: '10px',
                                    }}>
                                        <div style={{
                                            border: '20px solid #ffF',
                                        }}>
                                            <img alt='images' src={scheme} width="100%" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        width: '80%',
                                        marginLeft: '20%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Fastyd is a Superapp that greatly improves Check-out processes on e-commerce employing Rapyd’s Checkout toolkit and APIs, while incorporating Web3 and blockchain capabilities and transactions.
                                        </p>
                                        <br />
                                        <Button className='fastButtonB' onClick={() => {
                                            window.open('https://devpost.com/software/fastyd', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="2">
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
    }
}

export default Fastyd;
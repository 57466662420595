import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import logo from '../assets/azure/logo.png';

import azure from '../assets/azure/azure.png';
import fpga from '../assets/azure/fpga.png';
import ai from '../assets/azure/ai.png';
import arm from '../assets/azure/arm.png';

import scheme from '../assets/azure/scheme.png';

class Azure extends Component {
    render() {
        if (isMobile) {
            return (
                <div>
                    <div className='azure-top'>
                        <div style={{
                            padding: '4vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="12">
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            opacity: '1',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '1vh',
                                        }}>
                                            <img src={logo} alt="industry"
                                                width={'80%'} />
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                            fontSize: '1.5rem',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Rehabilitation system based on Azure AI and an FPGA
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='azureButton' onClick={() => {
                                            window.open('https://www.youtube.com/watch?v=laALIdIb3Po', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '0vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Azure Rehab Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 0vw',
                            }}>
                                <Col xs="6">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={azure} alt="logo" width={'65%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Azure Cloud
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '3.8vh',
                                        }}>
                                            <img src={fpga} alt="logo" width={'60%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            FPGA
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={ai} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            AI Services
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={arm} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Robotics
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '8vh 0vh 16vw',
                            textAlign: 'center',
                        }}>
                            <Row>
                                <Col xs="12" style={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{
                                        width: '80%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            fontSize: "1.2rem",
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Rehabilitation system based on Azure AI and an FPGA device which detects rehabilitation movements and gives feedback to the patient through Azure's AI services.
                                        </p>
                                        <br />
                                        <Button className='azureButtonBm' onClick={() => {
                                            window.open('https://devpost.com/software/azure-rehab-rehabilitation-trainer-with-robotic-arm-and-ai', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="2">
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className='azure-top'>
                        <div style={{
                            padding: '8vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="4">
                                    <div style={{
                                        paddingLeft: '10vw',
                                    }}>
                                        <div style={{
                                            paddingTop: '18vh',
                                            paddingBottom: '1vh',
                                            fontSize: '4vw',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Azure Rehab
                                        </div>
                                        <div style={{
                                            paddingTop: '1vh',
                                            fontSize: '1.5vw',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Rehabilitation system based on Azure AI and an FPGA
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='azureButton' onClick={() => {
                                            window.open('https://www.youtube.com/watch?v=laALIdIb3Po', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="8">
                                    <div style={{
                                        opacity: '1',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '5vh',
                                    }}>
                                        <img src={logo} alt="industry" style={{
                                            height: '70vh',
                                        }} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Azure Rehab Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 10vw',
                            }}>
                                <Col xs="3">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={azure} alt="logo" width={'65%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Azure Cloud
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '3.8vh',
                                        }}>
                                            <img src={fpga} alt="logo" width={'60%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            FPGA
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={ai} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            AI Services
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="3">
                                    <div className='polygona'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={arm} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Robotics
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Azure Rehab Documentation
                            </h1>
                            <Row style={{
                                paddingTop: '10vh',
                            }}>
                                <Col xs="2">
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        border: '20px solid #064d8c',
                                        borderRadius: '10px',
                                    }}>
                                        <div style={{
                                            border: '20px solid #ffF',
                                        }}>
                                            <img alt='images' src={scheme} width="100%" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        width: '80%',
                                        marginLeft: '20%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Rehabilitation system based on Azure AI and an FPGA device which detects rehabilitation movements and gives feedback to the patient through Azure's AI services.
                                        </p>
                                        <br />
                                        <Button className='azureButtonB' onClick={() => {
                                            window.open('https://devpost.com/software/azure-rehab-rehabilitation-trainer-with-robotic-arm-and-ai', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="2">
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
    }
}

export default Azure;
import React, { Component } from 'react';
import logo from '../assets/logo.png';
import logob from '../assets/logob.png';
import { Row, Col } from 'reactstrap';
import DropMenu from './dropdown';
import { FaFacebook, FaTwitter, FaLinkedin, FaTable, FaHandPeace } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import ham from '../assets/hamburger.png';

class Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            mobileHam: false, // false
            mobileProj: false, // false
            mobileHamOpen: false, // false
            mobileProjOpen: false, // false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.setState({
                hidden: window.scrollY > window.innerHeight,
            });
        });
    }

    render() {
        if (isMobile) {
            return (
                <div
                    style={{
                        overflowX: 'hidden',
                    }}
                >
                    <div style={{
                        height: "8vh",
                        position: "fixed",
                        top: "0px",
                        background: "rgba(255,255,255,0.8)",
                        zIndex: "100",
                        width: "100vw",
                    }}>
                        <Row md="3"
                            style={{
                                padding: "2vh 8vw",
                            }}>
                            <Col xs="2">
                                <img src={logo} alt="logo" width={"150px"} onClick={() => {
                                    window.location.href = "/";
                                }} />
                            </Col>
                            <Col xs="8">
                            </Col>
                            <Col xs="2" onClick={() => {
                                if (this.state.mobileHam) {
                                    this.setState({
                                        mobileHam: !this.state.mobileHam
                                    }, () =>
                                        setTimeout(() =>
                                            this.setState({
                                                mobileHamOpen: !this.state.mobileHamOpen
                                            })
                                            , 100)
                                    )
                                }
                                else {
                                    this.setState({
                                        mobileHam: !this.state.mobileHam,
                                        mobileHamOpen: !this.state.mobileHamOpen
                                    })
                                }
                            }}>
                                <img alt="logo ham" src={ham} width={"44px"} />
                            </Col>
                        </Row>
                        <Row
                            className={this.state.mobileHam ? "m-fadeInm" : "m-fadeOutm"}
                            style={{
                                textAlign: "center",
                                background: "rgba(255,255,255,1)",
                                fontSize: "1.5rem",
                            }}
                        >
                            <hr />
                            <Col xs="12" style={{ margin: "0vh 0vh 2vh 0vh" }}
                                onClick={() => window.location.href = "/"}
                            >
                                Home
                            </Col>
                            <hr />
                            <Col xs="12" style={{ margin: "0vh 0vh 2vh 0vh" }}
                                onClick={() => {
                                    if (this.state.mobileProj) {
                                        this.setState({
                                            mobileProj: !this.state.mobileProj
                                        }, () =>
                                            setTimeout(() =>
                                                this.setState({
                                                    mobileProjOpen: !this.state.mobileProjOpen
                                                })
                                                , 100)
                                        )
                                    }
                                    else {
                                        this.setState({
                                            mobileProj: !this.state.mobileProj,
                                            mobileProjOpen: !this.state.mobileProjOpen
                                        })
                                    }
                                }}
                            >
                                Projects
                            </Col>
                            <hr />
                            <Col hidden={!this.state.mobileProjOpen} className={this.state.mobileProj ? "m-fadeInm" : "m-fadeOutm"} xs="12" style={{ margin: "0vh 0vh 2vh 0vh", fontSize: "1.2rem" }}>
                                <Row>
                                    <Col xs="12" style={{ margin: "0vh 0vh 2vh 0vh" }}
                                        onClick={() => window.location.href = "/fastyd"}
                                    >
                                        Fastyd
                                    </Col>
                                    <hr />
                                    <Col xs="12" style={{ margin: "0vh 0vh 2vh 0vh" }}
                                        onClick={() => window.location.href = "/neo-effisend"}
                                    >
                                        EffiSend
                                    </Col>
                                    <hr />
                                    <Col xs="12" style={{ margin: "0vh 0vh 1vh 0vh" }}
                                        onClick={() => window.location.href = "/azure-rehab"}
                                    >
                                        Azure Rehab
                                    </Col>
                                </Row>
                                <hr style={{
                                    marginBottom: "0vh"
                                }} />
                            </Col>
                            <Col xs="12" style={{ margin: "0vh 0vh 2vh 0vh" }} onClick={() => window.location.href = "/about"}>
                                About Us
                            </Col>
                        </Row>
                    </div>
                    <div style={{
                        paddingTop: "8vh"
                    }}>
                        {this.props.children}
                    </div>
                    <div style={{
                        background: '#23356D',
                        height: 'auto',
                        paddingLeft: '4vw',
                        paddingRight: '4vw',
                    }}>
                        <Row style={{
                            color: "white",
                            textAlign: "center"
                        }}>
                            {
                                /*
                                <Col xs="12" style={{ paddingTop: "20px" }}>
                                <h3>
                                    About Us
                                </h3>
                                <h5 style={{
                                    color: 'white',
                                    fontSize: '1.4rem',
                                    fontWeight: 'lighter',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    paddingRight: '2vw',
                                    paddingTop: '1vw',
                                }}>
                                    We are a Tech Startup, based on a multidisciplinary team of engineers specialized in medical technology, IoT, Data analysis, AI/ML, Blockchain.
                                </h5>
                            </Col> 
                            */
                            }
                            <Col xs="12" style={{ paddingTop: "20px" }}>
                                <h3>
                                    Contact Us
                                </h3>
                                <h1>
                                    <a href="mailto:contact@blankit.com.mx"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        contact@blankit.com.mx
                                    </a>
                                </h1>
                            </Col>
                            <Col xs="12" style={{ padding: "20px 0px" }}>
                                <h3>
                                    &nbsp;&nbsp;&nbsp;Keep In Touch
                                </h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    paddingTop: '1vh',
                                }}>
                                    <a href="https://www.facebook.com/blankitcorp/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaFacebook size={30} />
                                    </a>
                                    <a href="https://twitter.com/Blank_it_Corp"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank" rel="noopener noreferrer">
                                        <FaTwitter size={30} />
                                    </a>
                                    <a href="https://www.linkedin.com/company/blank-t/"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaLinkedin size={30} />
                                    </a>
                                    <a href="https://www.crunchbase.com/organization/blank-t#section-overview"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaTable size={30} />
                                    </a>
                                    <a href="https://angel.co/company/blank-t"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'white',
                                        }}
                                        target="_blank" rel="noopener noreferrer">
                                        <FaHandPeace size={30} />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div >
            )
        }
        else {
            return (
                <>
                    <div
                        style={{
                            overflowX: 'hidden',
                        }}
                    >
                        <div
                            className={this.state.hidden ? 'm-fadeOut' : 'm-fadeIn'}
                            style={{
                                padding: '1vh 8vw',
                                height: "8vh",
                                width: "100vw",
                                position: "fixed",
                                top: "0px",
                                background: "rgba(255,255,255,0.8)",
                                zIndex: "100",
                            }}>
                            <Row md="2">
                                <Col xs="2">
                                    <img src={logo} alt="logo" width={"180px"} onClick={() => {
                                        window.location.href = "/";
                                    }} />
                                </Col>
                                <Col xs="10"
                                    style={{
                                        paddingTop: "10px",
                                        paddingLeft: "10vw"
                                    }}>
                                    <Row>
                                        <Col xs="4">
                                            <div style={{
                                                cursor: "pointer",
                                            }} className='header-menu' onClick={() => {
                                                window.location.href = "/";
                                            }}>
                                                Home
                                            </div>
                                        </Col>
                                        <Col xs="4">
                                            <div style={{
                                                cursor: "pointer",
                                            }} className='header-menu'>
                                                <DropMenu title="Projects"
                                                    items={["Fastyd", "EffiSend", "Azure Rehab"]}
                                                    urls={["/fastyd", "/effisend", "/azure-rehab"]}
                                                />
                                            </div>
                                        </Col>
                                        <Col style={{
                                            cursor: "pointer",
                                        }} xs="4">
                                            <div className='header-menu' onClick={() => {
                                                window.location.href = "/about";
                                            }}>
                                                About Us
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {this.props.children}
                        </div>
                        <div>
                            <div style={{
                                background: '#23356D',
                                height: '30vh',
                                paddingTop: '3vh',
                                paddingLeft: '4vw',
                                paddingRight: '4vw',
                            }}>
                                <Row style={{
                                    color: "white"
                                }}>
                                    <Col xs="2">
                                        <img alt="logos" src={logob} width={"180px"} />
                                    </Col>
                                    <Col xs="3">
                                        <h3>
                                            About Us
                                        </h3>
                                        <h5 style={{
                                            color: 'white',
                                            fontSize: '1.2vw',
                                            fontWeight: 'lighter',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                            paddingRight: '2vw',
                                            paddingTop: '1vw',
                                        }}>
                                            We are a Tech Startup, based on a multidisciplinary team of engineers specialized in medical technology, IoT, Data analysis, AI/ML, Blockchain.
                                        </h5>
                                    </Col>
                                    <Col xs="4">
                                        <h3>
                                            Contact Us
                                        </h3>
                                        <h1>
                                            <a href="mailto:contact@blankit.com.mx"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                contact@blankit.com.mx
                                            </a>
                                        </h1>
                                    </Col>
                                    <Col xs="3">
                                        <h3>
                                            &nbsp;&nbsp;&nbsp;Keep In Touch
                                        </h3>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            paddingTop: '1vh',
                                        }}>
                                            <a href="https://www.facebook.com/blankitcorp/"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <FaFacebook size={30} />
                                            </a>
                                            <a href="https://twitter.com/Blank_it_Corp"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank" rel="noopener noreferrer">
                                                <FaTwitter size={30} />
                                            </a>
                                            <a href="https://www.linkedin.com/company/blank-t/"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <FaLinkedin size={30} />
                                            </a>
                                            <a href="https://www.crunchbase.com/organization/blank-t#section-overview"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <FaTable size={30} />
                                            </a>
                                            <a href="https://angel.co/company/blank-t"
                                                style={{
                                                    textDecoration: 'none',
                                                    color: 'white',
                                                }}
                                                target="_blank" rel="noopener noreferrer">
                                                <FaHandPeace size={30} />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Base;
import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardFooter, CardImg } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import victor from "../assets/c-level/Victor Altamirano-small.png"
import alex from "../assets/c-level/Alejandro Sanchez-small.png"
import luis from "../assets/c-level/Luis Eduardo-small.png"
import lin from "../assets/lin.png"
import team from "../assets/c-level/team.png"

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show1: false,
            show2: false,
            show3: false
        };
    }
    render() {
        if (isMobile) {
            return (
                <div>
                    <div className='main-top'>
                        <div style={{
                            padding: '4vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="12">
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Who we are
                                        </div>
                                        <div style={{
                                            paddingTop: '1vh',
                                            fontSize: '1rem',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            <span style={{ fontSize: "1.5rem" }}>
                                                About Us
                                            </span>
                                            <br />
                                            We are a Tech Startup, based on a multidisciplinary team of engineers specialized in medical technology, IoT, AI, and Blockchain.
                                            <br />
                                            <br />
                                            <span style={{ fontSize: "2rem" }}>
                                                Mission
                                            </span>
                                            <br />
                                            Accelerate the adoption of digital technologies.
                                            <br />
                                            <br />
                                            <span style={{ fontSize: "2rem" }}>
                                                Vision
                                            </span>
                                            <br />
                                            Affordable and complete integration of innovative technologies, it should be seamless and almost invisible.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div style={{
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '5vh',
                                        border: "20px solid #23356D",
                                        borderRadius: "25px",
                                        width: "74%"
                                    }}>
                                        <img style={{
                                            borderRadius: "25px"
                                        }} src={team} alt="industry" width={'101%'} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={{ paddingTop: "10vh" }} />
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 0vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Founding Members
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 6vw',
                            }}>
                                <Col xs="12">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            src={victor}
                                            alt="victor"
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/victor-alonso-altamirano-izquierdo-311437137/", "_blank")
                                            }}
                                        />
                                        <CardBody
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.5rem"
                                            }}>
                                            Victor Altamirano
                                        </CardBody>
                                        <CardFooter
                                            style={{
                                                fontSize: "1.3rem"
                                            }}>
                                            Chief Technology Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <p />
                                <Col xs="12">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            width="30vw"
                                            src={luis}
                                            alt="luis"
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/luis-eduardo-arevalo-oliver-989703122/", "_blank")
                                            }}
                                        />
                                        <CardBody
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.5rem"
                                            }}>
                                            Luis Eduardo Arevalo
                                        </CardBody>
                                        <CardFooter
                                            style={{
                                                fontSize: "1.3rem"
                                            }}>
                                            Chief Executive Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <p />
                                <Col xs="12">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            width="30vw"
                                            src={alex}
                                            alt="alex"
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/alejandro-s%C3%A1nchez-guti%C3%A9rrez-11105a157/", "_blank")
                                            }}
                                        />
                                        <CardBody
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.5rem"
                                            }}>
                                            Alejandro Sanchez
                                        </CardBody>
                                        <CardFooter
                                            style={{
                                                fontSize: "1.3rem"
                                            }}>
                                            Chief Marketing Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div>
                    <div className='main-top'>
                        <div style={{
                            padding: '15vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="6">
                                    <div style={{
                                        paddingLeft: '10vw',
                                    }}>
                                        <div style={{
                                            paddingTop: '4vh',
                                            paddingBottom: '4vh',
                                            fontSize: '3vw',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Who we are
                                        </div>
                                        <div style={{
                                            paddingTop: '2vh',
                                            fontSize: '1vw',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            <span style={{ fontSize: "2rem" }}>
                                                About Us
                                            </span>
                                            <br />
                                            We are a Tech Startup, based on a multidisciplinary team of engineers specialized in medical technology, IoT, AI, and Blockchain.
                                            <br />
                                            <br />
                                            <span style={{ fontSize: "2rem" }}>
                                                Mission
                                            </span>
                                            <br />
                                            Accelerate the adoption of digital technologies.
                                            <br />
                                            <br />
                                            <span style={{ fontSize: "2rem" }}>
                                                Vision
                                            </span>
                                            <br />
                                            Affordable and complete integration of innovative technologies, it should be seamless and almost invisible.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div style={{
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '5vh',
                                        border: "20px solid",
                                        borderRadius: "25px",
                                        width: "74%"
                                    }}>
                                        <img src={team} alt="industry" width={'101%'} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 0vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                Founding Members
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 20vw',
                            }}>
                                <Col xs="4">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            src={victor}
                                            alt="victor"
                                        />
                                        <div
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/victor-alonso-altamirano-izquierdo-311437137/", "_blank")
                                            }}
                                            onMouseEnter={
                                                () => this.setState({
                                                    show1: true
                                                })
                                            }
                                            onMouseLeave={
                                                () => this.setState({
                                                    show1: false
                                                })
                                            }
                                            className={this.state.show1 ? 'overlay' : "overlayx"}
                                            style={{
                                                width: "100%",
                                                height: "80%",
                                                cursor: "pointer"
                                            }}>
                                            <img alt='link1' src={lin} style={{
                                                width: "80%",
                                                paddingTop: "50px"
                                            }}>
                                            </img>
                                        </div>
                                        <CardBody
                                            onMouseEnter={
                                                () => this.setState({
                                                    show1: false
                                                })
                                            }
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.3rem"
                                            }}>
                                            Victor Altamirano
                                        </CardBody>
                                        <CardFooter
                                            onMouseEnter={
                                                () => this.setState({
                                                    show1: false
                                                })
                                            }
                                        >
                                            Chief Technology Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            width="30vw"
                                            src={luis}
                                            alt="luis"
                                        />
                                        <div
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/luis-eduardo-arevalo-oliver-989703122/", "_blank")
                                            }}
                                            onMouseEnter={
                                                () => this.setState({
                                                    show2: true
                                                })
                                            }
                                            onMouseLeave={
                                                () => this.setState({
                                                    show2: false
                                                })
                                            }
                                            className={this.state.show2 ? 'overlay' : "overlayx"}
                                            style={{
                                                width: "100%",
                                                height: "80%",
                                                cursor: "pointer"
                                            }}>
                                            <img alt='link2' src={lin} style={{
                                                width: "80%",
                                                paddingTop: "50px"
                                            }}>
                                            </img>
                                        </div>
                                        <CardBody
                                            onMouseEnter={
                                                () => this.setState({
                                                    show2: false
                                                })
                                            }
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.3rem"
                                            }}>
                                            Luis Eduardo Arevalo
                                        </CardBody>
                                        <CardFooter
                                            onMouseEnter={
                                                () => this.setState({
                                                    show2: false
                                                })
                                            }
                                        >
                                            Chief Executive Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col xs="4">
                                    <Card>
                                        <CardImg
                                            style={{
                                                width: "100%"
                                            }}
                                            width="30vw"
                                            src={alex}
                                            alt="alex"
                                        />
                                        <div
                                            onClick={() => {
                                                window.open("https://www.linkedin.com/in/alejandro-s%C3%A1nchez-guti%C3%A9rrez-11105a157/", "_blank")
                                            }}
                                            onMouseEnter={
                                                () => this.setState({
                                                    show3: true
                                                })
                                            }
                                            onMouseLeave={
                                                () => this.setState({
                                                    show3: false
                                                })
                                            }
                                            className={this.state.show3 ? 'overlay' : "overlayx"}
                                            style={{
                                                width: "100%",
                                                height: "80%",
                                                cursor: "pointer"
                                            }}>
                                            <img alt='link3' src={lin} style={{
                                                width: "80%",
                                                paddingTop: "50px"
                                            }}>
                                            </img>
                                        </div>
                                        <CardBody
                                            onMouseEnter={
                                                () => this.setState({
                                                    show3: false
                                                })
                                            }
                                            style={{
                                                fontWeight: "bolder",
                                                fontSize: "1.3rem"
                                            }}>
                                            Alejandro Sanchez
                                        </CardBody>
                                        <CardFooter
                                            onMouseEnter={
                                                () => this.setState({
                                                    show3: false
                                                })
                                            }
                                        >
                                            Chief Marketing Officer
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default About;
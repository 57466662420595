import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Col, Row } from 'reactstrap';

import prop from '../assets/effisend/prop.png';

import stripe from '../assets/effisend/stripe.png';

import scheme from '../assets/effisend/scheme.png';

import { FaEthereum, FaTwitter } from "react-icons/fa";
import { MdPointOfSale } from "react-icons/md";


class EffiSend extends Component {

    render() {
        if (isMobile) {
            return (
                <div>
                    <div className='neo-top'>
                        <div style={{
                            padding: '4vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="12">
                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <div style={{
                                            opacity: '1',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '1vh',
                                        }}>
                                            <img src={prop} alt="industry"
                                                width={'80%'} />
                                        </div>
                                        <div style={{
                                            paddingTop: '3vh',
                                            fontSize: '3rem',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            EffiSend
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                            fontSize: '1.5rem',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Effisend is a Mobile-First wallet, cash out ramp and Point of Sale Superapp.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='neoButton' onClick={() => {
                                            window.open('https://puzzled-plume-e93.notion.site/Effisend-28877442b7ae4268a40dec2c0915c3bd', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '0vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                EffiSend Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 0vw',
                            }}>
                                <Col xs="6">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <FaEthereum style={{fontSize:"5rem"}} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            EVM compatible Blockchains
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={stripe} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Rapyd APIs
                                        </h3>
                                    </div>
                                </Col>
                                <div style={{
                                    paddingBottom: "30px"
                                }} />
                                <Col xs="12">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <MdPointOfSale style={{fontSize:"5rem"}} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Point of Sale
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '8vh 0vh 16vw',
                            textAlign: 'center',
                        }}>
                            <Row>
                                <Col xs="12" style={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <div style={{
                                        width: '80%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            fontSize: "1.2rem",
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Effisend is a Mobile-First wallet, cash out ramp and Point of Sale Superapp. We combine TradFi through Rapyd with Web3 to improve Financial Inclusion in Mexico and Latin America.
                                        </p>
                                        <br />
                                        <Button className='neoButtonBm' onClick={() => {
                                            window.open('https://puzzled-plume-e93.notion.site/Effisend-28877442b7ae4268a40dec2c0915c3bd', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="2">
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className='neo-top'>
                        <div style={{
                            padding: '8vh 0vw',
                            color: '#fff',
                        }}>
                            <Row>
                                <Col xs="4">
                                    <div style={{
                                        paddingLeft: '10vw',
                                    }}>
                                        <div style={{
                                            paddingTop: '18vh',
                                            paddingBottom: '4vh',
                                            fontSize: '4vw',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            EffiSend
                                        </div>
                                        <div style={{
                                            paddingTop: '2vh',
                                            fontSize: '1.5vw',
                                            fontFamily: 'Poppins, sans-serif',
                                        }}
                                        >
                                            Effisend is a Mobile-First wallet, cash out ramp and Point of Sale Superapp.
                                        </div>
                                        <div style={{
                                            paddingTop: '4vh',
                                        }} />
                                        <Button className='neoButton' onClick={() => {
                                            window.open('https://puzzled-plume-e93.notion.site/Effisend-28877442b7ae4268a40dec2c0915c3bd', '_blank');

                                        }}>
                                            Learn More
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="8">
                                    <div style={{
                                        opacity: '1',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <img src={prop} alt="industry"
                                            className='neo-prop'
                                            style={{
                                                width: '100%',
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two'>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                EffiSend Technologies
                            </h1>
                            <Row style={{
                                paddingTop: '5vh',
                                margin: '0vh 10vw',
                            }}>
                                <Col xs="4">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.8vh',
                                        }}>
                                            <FaEthereum style={{fontSize:"5rem", alignSelf:"center"}} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            EVM compatible Blockchains
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.6vh',
                                        }}>
                                            <img src={stripe} alt="logo" width={'70%'} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Rapyd APIs
                                        </h3>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div className='polygonn'>
                                        <div style={{
                                            paddingTop: '2.8vh',
                                        }}>
                                            <MdPointOfSale style={{fontSize:"5rem"}} />
                                        </div>
                                    </div>
                                    <div>
                                        <h3>
                                            Point of Sale
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                EffiSend Documentation
                            </h1>
                            <Row style={{
                                paddingTop: '10vh',
                            }}>
                                <Col xs="2">
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        border: '20px solid #FE752F',
                                        borderRadius: '10px',
                                    }}>
                                        <div style={{
                                            border: '20px solid #ffF',
                                        }}>
                                            <img alt='images' src={scheme} width="100%" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div style={{
                                        width: '80%',
                                        marginLeft: '20%',
                                    }}>
                                        <h1>Project</h1>
                                        <br />
                                        <p style={{
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            textJustify: 'inter-word',
                                        }}>
                                            Effisend is a Mobile-First wallet, cash out ramp and Point of Sale Superapp. We combine TradFi through Rapyd with Web3 to improve Financial Inclusion in Mexico and Latin America.
                                        </p>
                                        <br />
                                        <Button className='neoButtonB' onClick={() => {
                                            window.open('https://puzzled-plume-e93.notion.site/Effisend-28877442b7ae4268a40dec2c0915c3bd', '_blank');

                                        }}>
                                            Open Documentation
                                        </Button>
                                    </div>
                                </Col>
                                <Col xs="2">
                                </Col>
                            </Row>
                        </div>
                    </div >
                    <div className='main-two' style={{
                        background: "whitesmoke",
                    }}>
                        <div style={{
                            padding: '10vh 0vh 10vw',
                            textAlign: 'center',
                        }}>
                            <h1>
                                EffiSend Social Networks
                            </h1>
                            <Row style={{
                                paddingTop: '10vh',
                            }}>
                                <Col xs="12">
                                    <FaTwitter style={{
                                        cursor: "pointer",
                                    }}
                                        color='#1DA1F2'
                                        size={"5vw"}
                                        onClick={() => window.open('https://twitter.com/NeoEffisend', "_blank")}
                                    />
                                    <div style={{
                                        fontSize: '3rem',
                                        color: '#1DA1F2'
                                    }}>
                                        Twitter
                                    </div>
                                </Col>
                                <Col xs="6">
                                    
                                </Col>
                            </Row>
                        </div>
                    </div >
                </div>
            );
        }
    }
}

export default EffiSend;